<template>
  <b-card>
    <!-- form -->
    <b-form
      @submit.prevent="updateUser">
      <b-row>
        <b-col sm="12">
          <h6 class="section-label mb-1">Informatie</h6>
        </b-col>

        <!-- birth date -->
        <b-col md="6">
          <b-form-group label-for="example-datepicker" label="Geboortedatum">
            <cleave
              id="birthday"
              class="form-control"
              :raw="false"
              :options="cleaveBirth"
              :disabled="loading"
              v-model="user.birthday"
              placeholder="dd/mm/jjjj"
            />
          </b-form-group>
        </b-col>
        <!--/ birth date -->

        <b-col sm="12">
          <h6 class="section-label mb-1 mt-2">Contactgegevens</h6>
        </b-col>

        <!-- phone -->
        <b-col md="8" class="mb-2">
          <b-row :key="k" v-for="(p, k) in user.phones">
            <b-col cols="10">
              <b-form-group label-for="phone" :label="'Telefoonnummer '+(k+1)">
                <cleave
                  class="form-control"
                  :options="cleavePhone"
                  :disabled="loading"
                  v-model="user.phones[k]"
                  :class="$v.user.phones.$each[k].$error ? 'is-invalid' : ''"
                  placeholder="Telefoonnummer"
                />
              </b-form-group>
            </b-col>
            <b-col v-if="k>0" cols="2">
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                class="mt-0 mt-md-2"
                @click="removePhone(k)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </b-col>
          </b-row>
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-success"
            size="sm"
            @click="addPhone"
          >
            <feather-icon class="mr-1" icon="PlusIcon" />
            <span>Telefoonnummer toevoegen</span>
          </b-button>
        </b-col>
        <!-- phone -->

        <!-- email -->
        <b-col md="8" class="mb-2">
          <b-row :key="k" v-for="(p, k) in user.emails">
            <b-col cols="10">
              <b-form-group label-for="email" :label="'E-mail '+(k+1)">
                <b-form-input
                  class="form-control"
                  v-model="user.emails[k]"
                  :name="'email-'+k"
                  :class="$v.user.emails.$each[k].$error ? 'is-invalid' : ''"
                  :disabled="loading || k == 0"
                  placeholder="E-mail"
                />
              </b-form-group>
            </b-col>
            <b-col v-if="k>0" cols="2">
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                class="mt-0 mt-md-2"
                @click="removeEmail(k)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </b-col>
          </b-row>
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-success"
            size="sm"
            @click="addEmail"
          >
            <feather-icon class="mr-1" icon="PlusIcon" />
            <span>E-mail toevoegen</span>
          </b-button>
        </b-col>
        <!-- email -->

         <!-- email -->
        <b-col md="8" class="mb-2">
          <b-row :key="k" v-for="(p, k) in user.addresses">
            <b-col sm="12">
              <h6 class="form-label mb-1 mt-2">{{ k == 0 ? 'Hoofdadres' : ('Adres ' + (k+1))}}</h6>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Straat">
                <b-form-input
                  class="form-control"
                  v-model="user.addresses[k].street"
                  :class="$v.user.addresses.$each[k].street.$error ? 'is-invalid' : ''"
                  :disabled="loading"
                  placeholder="Straat"
                />
              </b-form-group>
            </b-col>
            <b-col cols="2">
              <b-form-group label="Nr.">
                <b-form-input
                  class="form-control"
                  :disabled="loading"
                  v-model="user.addresses[k].housenumber"
                  :class="$v.user.addresses.$each[k].housenumber.$error ? 'is-invalid' : ''"
                  placeholder="Nr."
                />
              </b-form-group>
            </b-col>
            <b-col cols="2">
              <b-form-group label="Bus">
                <b-form-input
                  class="form-control"
                  :disabled="loading"
                  v-model="user.addresses[k].addition"
                  :class="$v.user.addresses.$each[k].addition.$error ? 'is-invalid' : ''"
                  placeholder="Bus"
                />
              </b-form-group>
            </b-col>
            <b-col v-if="k>0" cols="2">
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                class="mt-0 mt-md-2"
                @click="removeAddress(k)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </b-col>
            <b-col cols="4">
              <b-form-group label="Postcode">
                <b-form-input
                  class="form-control"
                  :disabled="loading"
                  v-model="user.addresses[k].postal"
                  :class="$v.user.addresses.$each[k].postal.$error ? 'is-invalid' : ''"
                  placeholder="Postcode"
                />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="Plaats">
                <b-form-input
                  class="form-control"
                  :disabled="loading"
                  v-model="user.addresses[k].city"
                  :class="$v.user.addresses.$each[k].city.$error ? 'is-invalid' : ''"
                  placeholder="Plaats"
                />
              </b-form-group>
            </b-col>

          </b-row>
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-success"
            size="sm"
            @click="addAddress"
          >
            <feather-icon class="mr-1" icon="PlusIcon" />
            <span>Adres toevoegen</span>
          </b-button>
        </b-col>
        <!-- email -->

        <b-col sm="12">
          <h6 class="section-label mb-1 mt-2">Extra</h6>
        </b-col>
        <b-col sm="12"> Gebruiker sinds {{ accountCreated }} </b-col>

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            type="submit"
            :disabled="loading"
            class="mt-1 mr-1"
          >
           <b-spinner v-if="loading" small></b-spinner>
            Opslaan
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            class="mt-1"
            variant="outline-secondary"
            @click.prevent="resetForm"
          >
            Annuleren
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import moment from "moment";
import { auth, db } from "@/firebase/firebaseConfig";
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BFormTextarea,
  BSpinner
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import Cleave from "vue-cleave-component";

import { Dutch } from "flatpickr/dist/l10n/nl.js";

import {required, email, minLength, numeric} from 'vuelidate/lib/validators'

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.be";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    vSelect,
    Cleave,
    BSpinner
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      user: { ...this.$store.state.AppActiveUser, ...{} },
      cleaveBirth: {
        date: true,
        delimiter: "/",
        datePattern: ["d", "m", "Y"],
      },
      cleavePhone: {
        phone: true,
        phoneRegionCode: "BE",
      },
    };
  },
  created(){
   this.resolveEmptyArrays();
  },
  methods: {
    updateUser(){
      if(!this.$v.$invalid){
        this.loading = true;
        this.user.emails = this.user.emails.filter(function (el) {
          return el != "" && el != null;
        });
        this.user.phones = this.user.phones.filter(function (el) {
          return el != "" && el != null;
        });
        if(this.user.phones[0] && this.user.phones[0] != this.user.phone){
          this.user.phone = this.user.phones[0];
        }
      db.collection('users').doc(auth.currentUser.uid).update(this.user).then(() => {
        this.loading = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Contactgegevens aangepast!',
            icon: "CheckIcon",
            variant: "success",
          },
        });
      }).catch(error =>{
        this.loading = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            text: error.message,
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
      });
      } else {
        this.$v.$touch();
      }
      
    },
    resetForm() {
      this.user = { ...this.$store.state.AppActiveUser, ...{} };
      this.resolveEmptyArrays();
    },
    resolveEmptyArrays(){
       if(this.user.phones.length < 1){
        this.user.phones.push(this.user.phone ?? "");
      }
      if(this.user.emails.length < 1){
        console.log(this.user.email);
        this.user.emails.push(this.user.email ?? "");
      }
      if(this.user.addresses.length < 1){
        this.user.addresses.push(this.user.address ?? {});
      }
    },
    addPhone(){
      this.user.phones.push('');
    },
    removePhone(index){
      this.user.phones.splice(index,1);
    },
    addEmail(){
      this.user.emails.push('');
    },
    removeEmail(index){
      this.user.emails.splice(index,1);
    },
    addAddress(){
      this.user.addresses.push({});
    },
    removeAddress(index){
      this.user.addresses.splice(index,1);
    }
  },
  computed: {
    accountCreated() {
      return moment(auth.currentUser.metadata.creationTime).format(
        "DD MMMM YYYY[,] h[u]mm"
      );
    },
  },
  validations: {
    user:{
      emails:{
        $each:{
          required, 
          email
        }
      },
      phones:{
        $each:{ 
          minLength: minLength(9)
        }
      },
      addresses:{
        $each:{
          street: {
            minLength: minLength(2)
          },
          housenumber: {
            numeric
          },
          addition: {
            
          },
          postal: {
            numeric
          },
          city: {
            
          }
        }
      },
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
