<template>
<b-overlay :show="loading" rounded="sm">
  <b-card>
     <div class="d-flex align-items-center mb-2">
            <feather-icon icon="EyeIcon" size="18" />
            <h4 class="mb-0 ml-75">Profielfoto</h4>
          </div>
     <!-- media -->
    <b-media no-body class="mb-2">
      <b-media-aside>
        <b-link>
          <b-avatar
            ref="previewEl"
            size="100px"
            variant="light-primary"
            :text="initals"
            :src="profilePic"
            :disabled="loading"
          />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
           :disabled="loading"
          @click="$refs.refInputEl.$el.click()"
        >
          Aanpassen
        </b-button>
        <b-form-file
          ref="refInputEl"
          v-model="profileFile"
          accept=".jpg, .png"
          :hidden="true"
          plain
           :disabled="loading"
          @input="inputImageRenderer"
        />
        <!--/ upload button -->
        <b-card-text>JPG of PNG bestanden</b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->
    <!-- form -->
    <b-form
      @submit.prevent="changeUser">
      <b-row>
        <b-col sm="12">
           <div class="d-flex align-items-center mb-2 mt-2">
            <feather-icon icon="InfoIcon" size="18" />
            <h4 class="mb-0 ml-75">Algemeen</h4>
          </div>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Voornaam" label-for="account-firstname">
            <b-form-input
              v-model="user.firstname"
              :disabled="loading"
              :class="$v.user.firstname.$error ? 'is-invalid' : ''"
              placeholder="Voornaam"
              name="firstname"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Naam" label-for="account-lastname">
            <b-form-input
              v-model="user.lastname"
              name="lastname"
              :class="$v.user.lastname.$error ? 'is-invalid' : ''"
              :disabled="loading"
              placeholder="Naam"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Weergavenaam" label-for="account-displayname">
            <b-form-input
              v-model="user.displayName"
              :class="$v.user.displayName.$error ? 'is-invalid' : ''"
              name="displayname"
              :disabled="loading"
              placeholder="Weergavenaam"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12">
          <div class="d-flex align-items-center mb-2 mt-2">
            <feather-icon icon="MailIcon" size="18" />
            <h4 class="mb-0 ml-75">E-mail</h4>
          </div>
        </b-col>
        <b-col sm="6">
          <b-form-group label="E-mail" label-for="account-e-mail">
            <b-form-input
              v-model="user.email"
              :class="$v.user.email.$error ? 'is-invalid' : ''"
              name="email"
              :disabled="loading"
              placeholder="E-mail"
            />
          </b-form-group>
        </b-col>

        <!-- alert -->
        <b-col v-if="!accountVerified" cols="12" class="mt-75">
          <b-alert show variant="warning" class="mb-50">
            <h4 class="alert-heading">
              Je e-mailadres werd nog niet geverifieërd.
            </h4>
            <div class="alert-body">
              <b-link class="alert-link" @click="resendVerification">
                Verificatie e-mail opnieuw verzenden
              </b-link>
            </div>
          </b-alert>
        </b-col>
        <!--/ alert -->

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            :disabled="loading"
            type="submit"
          >
          <b-spinner v-if="loading" small></b-spinner>
            Opslaan
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            @click.prevent="resetForm"
          >
            Annuleren
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
  </b-overlay>
</template>

<script>
import { auth, db } from "@/firebase/firebaseConfig";
import { required, email } from "vuelidate/lib/validators";
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BSpinner,
  BAvatar,
  BOverlay
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BSpinner,
    BAvatar,
    BOverlay
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      user: { ...this.$store.state.AppActiveUser, ...{} },
      loading: false,
      profileFile: null,
      profilePic: this.$store.state.AppActiveUser.profilePic ? this.$store.state.AppActiveUser.profilePic.medium : null,
    };
  },
  methods: {
    changeUser() {
      if (!this.$v.$invalid) {
        this.loading = true;
        const payload = {
          user: auth.currentUser,
            displayName: this.user.displayName,
            email: this.user.email,
            firstname: this.user.firstname,
            lastname: this.user.lastname,
            profilePic: this.user.profilePic,
            notify: (arg) => this.$toast(arg),
        };
        this.$store.dispatch('auth/updateUserProfile', payload).then((res) => {
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Account aangepast!',
                icon: "CheckIcon",
                variant: "success",
              },
            });
          })
          .catch((error) => {
            this.loading = false;
            this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            text: error.message,
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
            
          });
      } else {
        this.$v.$touch();
      }
    },
    resetForm() {
      this.user = { ...this.$store.state.AppActiveUser, ...{} };
    },
    resendVerification() {
      auth.currentUser
        .sendEmailVerification()
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "E-mail verzonden!",
              icon: "CheckIcon",
              text: "Check je mailbox 📩",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertCircleIcon",
              text: error.message,
              variant: "danger",
            },
          });
        });
    },
    inputImageRenderer(){
      const file = this.profileFile
      const reader = new FileReader()

      reader.addEventListener(
        'load',
        () => {
          this.profilePic = reader.result;
          this.uploadFile();
        },
        false,
      )

      if (file) {
        reader.readAsDataURL(file)
      }
    },
    uploadFile(){
      this.loading = true;
      this.$store.dispatch('uploadImageToPath', {
        imageName: `/users/${this.user.uid}/${this.user.uid}.${this.profileFile.name.split('.').pop()}`,
        file: this.profileFile,
        notify: (arg) => this.$toast(arg)
      }).then((result) => {
        const payload = {
          user: auth.currentUser,
            displayName: this.user.displayName,
            email: this.user.email,
            firstname: this.user.firstname,
            lastname: this.user.lastname,
            profilePic: result,
            notify: (arg) => this.$toast(arg),
        };
        this.$store.dispatch('auth/updateUserProfile', payload).then((res) => {
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Profielfoto aangepast!',
                icon: "CheckIcon",
                variant: "success",
              },
            });
          })
          .catch((error) => {
            this.loading = false;
            this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            text: error.message,
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
          });
        
      }).then(() => {
        this.loading = false;
      }).catch(error => {
        this.loading = false;
      })
    },
  },
  computed: {
    accountVerified() {
      return auth.currentUser.emailVerified;
    },
    initals(){
      if(this.user){
         let name = this.user.displayName
      let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

      let initials = [...name.matchAll(rgx)] || [];

      initials = (
        (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
      ).toUpperCase();

      return initials
      } else {
        return ""
      }
     
    }
  },
  validations: {
    user: {
      firstname: {
        required,
      },
      lastname: {
        required,
      },
      displayName: {
        required,
      },
      email: {
        required,
        email,
      },
    },
  },
};
</script>
