<template>
  <b-card>
    <!-- form -->
    <b-form @submit.prevent="changePassword" @reset.prevent="resetField" :disabled="loading">
      <b-row>
        <b-col sm="12">
        <h6 class="section-label mb-1">
        Wachtwoord veranderen
      </h6>
      </b-col>
        <!-- old password -->
        <b-col md="6">
          <b-form-group label="Oud wachtwoord" label-for="account-old-password">
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-old-password"
                v-model="oldPassword"
                :class="
                  $v.oldPassword.$error
                    ? 'is-invalid'
                    : ''
                "
                name="old-password"
                :type="passwordFieldTypeOld"
                :disabled="loading"
                placeholder="Oud wachtwoord"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconOld"
                  class="cursor-pointer"
                  @click="togglePasswordOld"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ old password -->
      </b-row>
      <b-row>
        <!-- new password -->
        <b-col md="6">
          <b-form-group
            label-for="account-new-password"
            label="Nieuw wachtwoord"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-new-password"
                v-model="newPassword"
                :type="passwordFieldTypeNew"
                :disabled="loading"
                :class="
                  $v.newPassword.$error
                    ? 'is-invalid'
                    : ''
                "
                name="new-password"
                placeholder="Nieuw wachtwoord"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconNew"
                  class="cursor-pointer"
                  @click="togglePasswordNew"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ new password -->

        <!-- retype password -->
        <b-col md="6">
          <b-form-group
            label-for="account-retype-new-password"
            label="Nieuw wachtwoord bevestigen"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-retype-new-password"
                v-model="confirmPassword"
                :class="
                  $v.confirmPassword.$error
                    ? 'is-invalid'
                    : ''
                "
                :type="passwordFieldTypeRetype"
                :disabled="loading"
                name="retype-password"
                placeholder="Nieuw wachtwoord"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconRetype"
                  class="cursor-pointer"
                  @click="togglePasswordRetype"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ retype password -->

        <!-- buttons -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            type="submit"
            class="mt-1 mr-1"
            :disabled="loading"
          >
           <b-spinner v-if="loading" small></b-spinner>
            Wachtwoord aanpassen
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            class="mt-1"
          >
            Annuleren
          </b-button>
        </b-col>
        <!--/ buttons -->
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
  BSpinner
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import firebase from 'firebase/app';
import { db, auth } from "@/firebase/firebaseConfig";
import { required, sameAs } from "vuelidate/lib/validators";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BSpinner
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      newPassword: "",
      confirmPassword: "",
      oldPassword: "",
      loading: false,
      passwordFieldTypeOld: "password",
      passwordFieldTypeNew: "password",
      passwordFieldTypeRetype: "password",
    };
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld =
        this.passwordFieldTypeOld === "password" ? "text" : "password";
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew =
        this.passwordFieldTypeNew === "password" ? "text" : "password";
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype =
        this.passwordFieldTypeRetype === "password" ? "text" : "password";
    },
    resetField() {
      this.newPassword = null;
      this.confirmPassword = null;
      this.oldPassword = null;
      this.$v.$reset();
    },
    changePassword() {
      if (!this.$v.$invalid) {
        this.loading = true;
        const credential = firebase.auth.EmailAuthProvider.credential(
          auth.currentUser.email,
          this.oldPassword
        );
        auth.currentUser
          .reauthenticateWithCredential(credential)
          .then(() => {
            return auth.currentUser.updatePassword(this.newPassword);
          })
          .then(() => {
            this.resetField();
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Je wachtwoord werd aangepast!",
                icon: "CheckIcon",
                variant: "success",
              },
            });
          })
          .catch((error) => {
            this.resetField();
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: error.message,
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          });
      } else {
        this.$v.$touch();
      }
    },
  },
  validations: {
    newPassword: {
      required,
    },
    oldPassword: {
      required,
    },
    confirmPassword: {
      sameAs: sameAs(function () {
        return this.newPassword;
      }),
    },
  },
};
</script>
