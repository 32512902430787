<template>
  <b-card>
    <b-form>
      <b-row>
        <b-col cols="12">
          <div class="d-flex align-items-center mb-2">
            <feather-icon icon="LinkIcon" size="18" />
            <h4 class="mb-0 ml-75">Scholen &amp; verenigingen</h4>
          </div>
        
        
        <b-row class="text-center">
        <b-col
          :key="k"
          v-for="(o, k) in $store.state.AppActiveUser.organisations"
          md="3"
          cols="6"
          class="mb-1"
        >
          <div class="mb-1">
            <b-avatar size="40" />
          </div>
          <b-card-text class="font-weight-bold">
            {{ o.shortName }}
          </b-card-text>

          <b-card-text class="mb-0">
            {{ o.type }}
          </b-card-text>
        </b-col>
        </b-row>
        </b-col>

        <b-col cols="12">
          <hr class="my-2" />
        </b-col>

        <!-- Profile Connections -->
        <b-col cols="12" class="mt-1">
          <div class="d-flex align-items-center mb-3">
            <feather-icon icon="UserIcon" size="18" />
            <h4 class="mb-0 ml-75">Account koppelingen</h4>
          </div>
          <div class="d-flex justify-content-between">
            <!-- microsoft profile -->
                <b-button
                  size="sm"
                  variant="dark"
                  :disabled="loading"
                  @click="linkWithMicrosoft"
                >
                <b-spinner class="mr-1" v-if="loading" small></b-spinner>
                  <img 
                  v-else
                    height="20px" 
                    class="mr-1"
                    src="@/assets/images/pages/login/microsoft.svg">
                    {{ microsoftLinked ? microsoftLinkedAccount : 'Aanmelden met Microsoft'}}
                </b-button>
            <!--/ microsoft profile -->
            <!-- google profile -->
                <b-button
                  size="sm"
                  variant="dark"
                  :disabled="loading"
                  @click="linkWithGoogle"
                >
                <b-spinner class="mr-1" v-if="loading" small></b-spinner>
                  <img 
                  v-else
                    height="20px" 
                    class="mr-1"
                    src="@/assets/images/pages/login/google.svg">
                    {{ googleLinked ? googleLinkedAccount : 'Aanmelden met Google'}}
                </b-button>
            <!--/ micrsoft profile -->
             <!-- Apple profile -->
                <b-button
                  size="sm"
                  variant="dark"
                  :disabled="loading"
                  @click="linkWithApple"
                >
                <b-spinner class="mr-1" v-if="loading" small></b-spinner>
                  <img 
                  v-else
                    height="20px" 
                    class="mr-1"
                    src="@/assets/images/pages/login/apple.svg">
                    {{ appleLinked ? appleLinkedAccount : 'Aanmelden met Apple'}}
                </b-button>
            <!--/ Apple profile -->
          </div>
        </b-col>

       
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import { auth } from "@/firebase/firebaseConfig";
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BAvatar,
  BSpinner
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BAvatar,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    linkWithMicrosoft(){
      if(!this.microsoftLinked){
         this.loading = true;
      const payload = {
            next: this.next,
            notify: (arg) => this.$toast(arg),
            closeAnimation: () => this.loading = false
          }
          this.$store.dispatch('auth/linkMicrosoft', payload).then(() => {
            
          })
      }
    
    },
    linkWithGoogle(){
      if(!this.googleLinked){
      this.loading = true;
      const payload = {
            next: this.next,
            notify: (arg) => this.$toast(arg),
            closeAnimation: () => this.loading = false
          }
          this.$store.dispatch('auth/linkGoogle', payload).then(() => {
            
          })
      }
    },
    linkWithApple(){
      if(!this.appleLinked){
      this.loading = true;
      const payload = {
            next: this.next,
            notify: (arg) => this.$toast(arg),
            closeAnimation: () => this.loading = false
          }
          this.$store.dispatch('auth/linkApple', payload).then(() => {
            
          })
      }
    }
  },
  computed:{
    microsoftLinked(){
      var result = false;
      if(auth.currentUser){
        auth.currentUser.providerData.forEach(p => {
          if(p.providerId == "microsoft.com"){
            result = true;
          }
        })
      }
      return result;

    },
    microsoftLinkedAccount(){
      var result = '';
      if(auth.currentUser){
        auth.currentUser.providerData.forEach(p => {
          if(p.providerId == "microsoft.com"){
            result = p.email;
          }
        })
      }
      return result;

    },
  
  googleLinked(){
      var result = false;
      if(auth.currentUser){
        auth.currentUser.providerData.forEach(p => {
          if(p.providerId == "google.com"){
            result = true;
          }
        })
      }
      return result;

    },
    googleLinkedAccount(){
      var result = '';
      if(auth.currentUser){
        auth.currentUser.providerData.forEach(p => {
          if(p.providerId == "google.com"){
            result = p.email;
          }
        })
      }
      return result;

    },
    appleLinked(){
      var result = false;
      if(auth.currentUser){
        auth.currentUser.providerData.forEach(p => {
          if(p.providerId == "apple.com"){
            result = true;
          }
        })
      }
      return result;

    },
    appleLinkedAccount(){
      var result = '';
      if(auth.currentUser){
        auth.currentUser.providerData.forEach(p => {
          if(p.providerId == "apple.com"){
            result = p.email;
          }
        })
      }
      return result;

    }
    
  }
};
</script>
